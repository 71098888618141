html, body {
	overflow-x: hidden !important;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Lora', serif;
	font-weight: bold;
}

p, a, ul li, ol, li, span, label, input, textarea {
	font-family: 'Merriweather', serif;
	font-size: 20px;
}

ul li {
	padding-bottom: 20px;
}

ul li li {
	margin-left: 30px;
	list-style-type: circle;
	padding-bottom: 0px;
}

a {
	color: rgb(223,143,55);	
}

a:hover {
	color: #d26e00;	
	text-decoration: none;
	border-bottom: 1px solid black;
}

img {
	max-width: 100%;
}

.App {
  text-align: center;
}

.App-button {
	border: 1px solid rgb(223,143,55);
	background: none;
	color: rgb(223,143,55);
	display: block;
	cursor: pointer;
	margin: 20px 0;
	max-width: 200px;
	padding: 10px 30px;
	transition: all .5s;
	text-align: center;
	text-decoration: none;
}

.App-button:hover {
	background: rgb(223,143,55);
	color: white;
}

.Banner .Banner-bg {
	background-position: center center;
	background-size: cover;
	height: 500px;
	position: relative;
	display: table;
	width: 100%;
}

.Banner .Banner-bg::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.7);
}

.Banner-title {
	color: white;
	display: table-cell;
	vertical-align: bottom;
	height: 100%;
	width: 100%;
	position: relative;
	text-align: left;
	padding-left: 60px;
	padding-bottom: 60px;
}

.Content {
	padding: 100px 30px;
}

.Content .container {
	max-width: 900px;
}

.Content .row {
	margin-bottom: 60px;
}

.Content-title {
	text-align: right;
}

.Content-text {
	text-align: left;
}

@media(max-width: 991px) {
	.Content-title {
		margin-bottom: 20px;
		text-align: left;
	}
}