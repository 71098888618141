
.Contact-wrap {
	padding: 60px 0;
}

.Contact .Intro-title {
	margin-bottom: 40px;
}

.Contact .Banner .Banner-bg {
	background-image: url('./Images/code.jpg');
}

.Content-title .fab {
	font-size: 1.5rem;
	margin: 10px 5px;
}

.Content-text form {
	margin-top: 40px;
}

.Content-text .group {
	padding-bottom: 20px;
}

.Content-text .group label {
	display: block;
	font-weight: bold;
}

.Content-text .group input[type=text] {
	border: 1px solid lightgray;
	display: block;
	height: 50px;
	padding: 0 20px;
	width: 100%;
}

.Content-text .group input[type=email] {
	border: 1px solid lightgray;
	display: block;
	height: 50px;
	padding: 0 20px;
	width: 100%;
}

.Content-text .group textarea {
	border-color: lightgray;
	display: block;
	padding: 20px;
	width: 100%;
}

input[type=text]:focus, input[type=email]:focus, textarea:focus {
	border: 1px solid rgb(223,143,55);
	box-shadow: none;
}