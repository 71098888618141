.Header.home .Logo-img.tieFighter {
    height: 60px;
    position: absolute;
    left: -100px;
    animation: TieFighter-logo-move 1.3s linear;
    pointer-events: none;
    opacity: 0;
}

.Header.home .Logo-img.xWing {
    height: 60px;
    position: absolute;
    left: 0px;
    animation: Xwing-logo-move 1.7s linear;
    pointer-events: none;
}

@keyframes TieFighter-logo-move {
    from {
        bottom: -60px;
        left: 100%;
        opacity: 1;
    }
    to {
        bottom: 0;
        left: -100px;
        opacity: 1;
    }
}

@keyframes Xwing-logo-move {
    from {
        bottom: -60px;
        left: 100%;
    }
    to {
        bottom: 0;
        left: 0px;
    }
}

.justify-content-lg-center {
    justify-content: center !important;
}

.Intro {
    align-items: center;
    display: flex;
    height: 100vh;
    position: relative;
    text-align: left;
}

.Intro a {
    cursor: pointer;
}

.Intro-next {
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
    animation: next-logo-move 1.5s infinite linear;
}

@keyframes next-logo-move {
    0%,
    100% {
        bottom: 150px;
    }
    50% {
        bottom: 170px;
    }
}

.Intro-next img {
    height: 50px;
    margin: 0 auto;
}

.Intro-title {
    color: rgb(223, 143, 55);
    font-size: 3rem;
    margin-bottom: 20px;
}

.Intro-description {
    font-weight: normal;
}

.Skills {
    align-items: center;
    display: flex;
    height: 100vh;
    position: relative;
}

.Skills-title {
    margin-bottom: 60px;
}
.Skills-title h2 {
    color: rgb(223, 143, 55);
    font-size: 3rem;
}

.Skills-item {
    margin-bottom: 50px;
}

.Skills-item img {
    max-height: 50px;
    margin-bottom: 30px;
}

.Skills-item h5 {
    color: rgba(0, 0, 0, 0.3);
}

.Skills a {
    cursor: pointer;
}

.Skills-next {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
    animation: skills-logo-move 1.5s infinite linear;
}

@keyframes skills-logo-move {
    0%,
    100% {
        bottom: 40px;
    }
    50% {
        bottom: 60px;
    }
}

.Skills-next img {
    height: 40px;
    margin: 0 auto;
}

.Project {
    padding: 130px 0;
    padding-top: 80px;
}

.Project a {
    text-decoration: none;
}

.Project a:hover {
    border: none;
}

.Project h3,
.Project p {
    color: black;
}

.Project-title {
    margin-bottom: 40px;
}

.Project-title h2 {
    color: rgb(223, 143, 55);
    font-size: 3rem;
    text-align: left;
}

.Project .container {
    max-width: 900px;
}

.Project .row {
    margin-bottom: 40px;
}

.Project .arrested .Project-image {
    background-image: url("./Images/ArrestedDevelopmentExtension.jpg");
    background-position: top center;
    background-size: cover;
}

.Project .todoapp .Project-image {
    background-image: url("./Images/todoapp.png");
    background-position: top center;
    background-size: cover;
}

.Project .cocktail .Project-image {
    background-image: url("./Images/cocktail.png");
    background-position: top center;
    background-size: cover;
}

.Project-image {
    cursor: pointer;
    min-height: 400px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    -webkit-transition: 0.55s ease;
    transition: 0.55s ease;
}

.Project-image:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
}

.Project-meta {
    text-align: left;
    margin-top: 40px;
}

@media (max-width: 768px) {
    .Intro-title,
    .Skills-title h2,
    .Project-title h2 {
        font-size: 2.5rem;
    }

    .Intro-description {
        font-size: 1.3rem;
    }

    .Skills-title {
        margin-bottom: 20px;
    }

    .Skills-title p {
        font-size: 18px;
    }

    .Skills-item {
        margin-bottom: 20px;
    }
    .Skills-item img {
        max-height: 40px;
        margin-bottom: 10px;
    }

    .Project-image {
        min-height: 300px;
    }

    @keyframes next-logo-move {
        0%,
        100% {
            bottom: 125px;
        }
        50% {
            bottom: 150px;
        }
    }

    @keyframes skills-logo-move {
        0%,
        100% {
            bottom: 20px;
        }
        50% {
            bottom: 40px;
        }
    }
}

@media (max-width: 768px) {
    .Intro,
    .Skills {
        height: inherit;
        padding: 60px 0;
    }

    .Intro-next,
    .Skills-next {
        display: none;
    }
}
