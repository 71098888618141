.About-section {
	border-top: 1px solid lightgray;
	padding-top: 60px;
	margin-bottom: 80px;
}

.About .About-title {
	margin-bottom: 40px;
	text-align: left;
}

.About .Intro-title {
	font-size: 2.5rem;
	padding-top: 60px;
	text-align: left;
}
.About .content {
	text-align: left;
}

.About .content .inner {
	padding: 0 20px;
}

.About .resume {
	text-align: center;
}

.About .resume .App-button {
	max-width: 250px;
	margin: 0 auto;
}

.About-img {
	margin-bottom: 40px;
}

.About .Banner .Banner-bg {
	background-image: url('./Images/macbook.jpg');
}

.carousel-caption {
	background: rgba(0,0,0,0.7);
	padding: 20px 40px;
}

.carousel-caption p{
	font-size: 15px;
}