.NavMobile {
	display: none;
	width: 100%;
}

.NavMobile .navbar-collapse {
	background: white;
	border-top: 3px solid rgb(223,143,55);
	margin-top: 39px;
	padding-top: 20px;
	position: relative;
}


.NavMobile .fas-bars {
	color: rgb(223,143,55);
}

.Nav-Wrap {
	width: 100%;
}

.Header	{
	align-items: center;
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 40px;
}

.Logo {
	position: absolute;
	top: 20px;
	width: 100%;
}

.Logo:after {
	content: '';
	width: 60px;
	height: 3px;
	position: absolute;
	bottom: -20px;
	left: 0;
	background: rgb(223,143,55);
}

.Logo a {
	display: block;
	height: 60px;
	width: 60px;
}

.Logo-img {
	height: 60px;
	position: absolute;
	left: 0;
	pointer-events: none;
}

.Logo-img img {
	height: 50px;
}

.Logo a:hover {
	border-bottom: none !important;
	text-decoration: none !important;
}

.Logo-img.tieFighter {
	margin-right: 20px;
	opacity: 0;
}

.Nav, .Header {
	width: 100%;
}

.Nav-items {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0px 15px;
	padding: 0;
	width: 100%;
}

.Nav-item a {
	color: black;
	font-family: 'Merriweather', serif;
	font-size: 18px;
	margin: 0 15px;
	padding: 20px 30px;
	position: relative;
	text-decoration: none;
}

.Nav-item a:hover {
	border-bottom: none;
}

.Nav-item a::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1px;
	top: 0;
	left: 0;
	background-color: rgb(223,143,55);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
  }

  .Nav-item a:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
  }
  
  .Nav-item.current a {
	color: rgb(223,143,55);
	border-top: 1px solid rgb(223,143,55);
  }

  .Nav-item.current a:after {
	  content: "";
	  background-color: transparent;
  }

@media(max-width: 991px) {
	.Nav-item a {
		font-size: 16px;
		padding: 10px 20px;
	}
}

@media(max-width: 768px) {

	.Header {
		display: inherit;
	}

	.Nav {
		display: none;
	}

	.NavMobile {
		display: block;
	}
}