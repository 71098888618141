
.Single .Banner .Banner-bg.arrested {
    background-image: url('./Images/ArrestedDevelopmentExtension.jpg');
	background-position: top center;
	background-size: cover;
}
.Single .Banner .Banner-bg.cocktail {
    background-image: url('./Images/cocktail.png');
	background-position: center center;
	background-size: cover;
}

.Single .Banner .Banner-bg.todoapp {
    background-image: url('./Images/todoapp.png');
	background-position: center center;
	background-size: cover;
}

.Single .Banner .Banner-bg:before {
    content: '';
    background: none;
}

.Single h3 {
    color: rgb(223,143,55);
}

.Single .App-button {
	padding: 10px 20px;
}
.Single .App-button:hover {
	border: none;
}