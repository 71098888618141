.Projects .Banner .Banner-bg {
	background-image: url('./Images/glass.jpg');
}


.Projects-loop {
	padding: 100px 0;
}

.Projects .Project {
	padding: 0;
}

.Projects .Project-image {
	min-height: 300px;
}