
.Footer {

	padding: 40px 0;
}

.Footer-copyright p {
	font-size: 12px;
	text-align: left;
}

.Footer-built {
	margin-top: 30px;
}
.Footer-built p, .Footer-built a {
	font-size: 12px;
	text-align: center;
}

.Footer-social {
	text-align: right;
}

.Footer-social i {
	font-size: 1.5rem;
	margin: 0 5px;
}

@media(max-width: 768px) { 
	.Footer-copyright p, .Footer-social {
		text-align: center;
	}
}